import { useCallback, useContext } from 'react';
import { InformacaoTelasAcessadasContext } from '~/context/InformacaoTelasAcessadas';
import api from '~/services/api';

interface UseLinkMaterialProps {
  fetchLinkMaterial: (codTela: number) => Promise<string>;
}

export const useLinkMaterial = (): UseLinkMaterialProps => {
  const { screensInformacoes } = useContext(InformacaoTelasAcessadasContext);

  const fetchLinkMaterial = useCallback(
    async (codTela: number): Promise<string> => {
      if (screensInformacoes) {
        const materialLocal = screensInformacoes.find(
          (item) => item.cod_tela === codTela,
        );

        if (materialLocal?.linkMaterial) return materialLocal.linkMaterial;
      }

      const { data } = await api.get(`telasLinkMaterial/${codTela}`);

      if (data.success && data.data?.link_material)
        return data.data.link_material;

      return '';
    },
    [screensInformacoes],
  );

  return { fetchLinkMaterial };
};
